import React from "react";
import SocialMedia from "../SocialMedia";

export default function PersonalInformation() {
    return (
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">Personal Info</h3>
            <span className="line mb-5"></span>
            <ul className="mt40 info list-unstyled">
                <li><span>Birthdate</span> : Jan 21, 1994 </li>
                <li><span>Email</span> : bikram.choudhury527@gmail.com</li>
                <li><span>Phone</span> : +91-7008529051</li>
                <li><span>Skype</span> : bikramchoudhury24 </li>
                <li><span>Address</span> :  JP Nagar 6th phase, Bengaluru</li>
            </ul>
            <SocialMedia dark />
        </div>
    )
}