import React from "react";
import Each from "../../common/Each";
import Card from "../../common/Card";

export default function Skills() {
    const allSkills = [
        { name: 'React', score: 90 },
        { name: 'JavaScript', score: 90 },
        { name: 'HTML & CSS', score: 80 },
        { name: 'Node.JS', score: 70 },
        { name: 'SQL', score: 60 },
        { name: 'MongoDB', score: 60 },
    ];
    return (
        <Card heading="Skills">
            <Each of={allSkills} render={Skill} />
        </Card>
    )
}

function Skill(props) {
    const { name, score } = props;
    return (
        <>
            <h6>{name}</h6>
            <div className="progress mb-3">
                <div
                    className="progress-bar bg-danger"
                    role="progressbar"
                    style={{ width: `${score}%` }}
                    aria-valuenow={100 - score}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
        </>
    )
}