import React from "react";

export default function Footer() {
    return (
        <footer className="footer py-3">
            <div className="container">
                <p className="small mb-0 text-light">
                    &copy; copyright 2024 <i className="ti-heart text-danger"></i> By 
                    <a href="https://www.linkedin.com/in/bikram-choudhury" target="_blank" rel="noreferrer">
                        <span className="text-danger" title="Bikram Choudhury"> Bikram Choudhury</span>
                    </a>
                </p>
            </div>
        </footer>
    )
}