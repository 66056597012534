import React, { Fragment } from 'react';
import './App.scss';
import Header from './components/Header';
import NavLinks from './components/NavLinks';
import HireMe from './components/HireMe';
import AboutUs from './components/AboutUs';
import MyResume from './components/MyResume';
import Achievements from './components/Achievements';
import ContactUS from './components/ContactUS';
import Footer from './components/Footer';

function App() {
  return (
    <Fragment>
      <Header />
      <NavLinks />
      <AboutUs />
      <HireMe />
      <MyResume />
      <Achievements />
      <ContactUS />
      <Footer />
    </Fragment>
  );
}

export default App;
