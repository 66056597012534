import React from 'react';
import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';
import Languages from './Languages';

export default function MyResume() {
    return (
        <section className="section" id="resume">
            <div className="container">
                <h2 className="mb-5"><span className="text-danger">My</span> Resume</h2>
                <div className="row">
                    <div className="col-6">
                        <Experience />
                    </div>
                    <div className="col-6">
                        <Skills />
                        <Education />
                        <Languages />
                    </div>
                </div>
            </div>
        </section>
    )
}