import React from "react";

export default function WhoAmI() {
    const handleClick = () => {
        window.open('/assets/resource/Bikram-Choudhury-Resume.pdf', '_blank');
    }
    return (
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">Who am I ?</h3>
            <span className="line mb-5"></span>
            <h5 className="mb-3">A Full Stack Web Developer Present In Our Lovely Earth</h5>
            <p className="mt-20">
                I have spent about 8+ years to gather impressive experiences in software programming and web developing methods.
                <br />
                <br />
                Being familiar with various programming techniques, I always updated with the latest web technologies, I like to implement best effective practices and strategies that justifies the development process.

            </p>
            <button className="btn btn-outline-danger" onClick={handleClick}><i className="icon-down-circled2 "></i>Download My CV</button>
        </div>
    )
}