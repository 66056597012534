import React from "react";
import GetInTouch from "./GetInTouch";
import SendMessage from "./SendMessage";

export default function ContactUS() {
    return (
        <div className="section contact" id="contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <SendMessage />
                    </div>
                    <div className="col-lg-5">
                        <GetInTouch />
                    </div>
                </div>

            </div>
        </div>
    )
}