import React from 'react';
import Each from '../../common/Each';
import Card from '../../common/Card';

export default function Education() {
    const allEducation = [
        {
            start: '2011',
            end: '2015',
            stream: 'B.Tech in Electrical and Electronics Engineering',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error corrupti recusandae obcaecati odit repellat ducimus cum, minus tempora aperiam at.'
        },
        {
            start: '2009',
            end: '2011',
            stream: 'CHSE (+2) in Science',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error corrupti recusandae obcaecati odit repellat ducimus cum, minus tempora aperiam at.'
        },
        {
            start: '2008',
            end: '2009',
            stream: 'High School Certificate',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error corrupti recusandae obcaecati odit repellat ducimus cum, minus tempora aperiam at.'
        },
        
    ];
    return (
        <Card heading="Education">
            <Each
                of={allEducation}
                render={(item, index) => {
                    return (
                        <>
                            <Exposure {...item} />
                            {(index + 1) < allEducation.length ? <hr /> : null}
                        </>
                    )
                }}
            />
        </Card>
    );
}

function Exposure(props) {
    const { start, end, stream } = props;
    return (
        <>
            <h6 className="title text-danger">{start} - {end}</h6>
            <p>{stream}</p>
        </>
    )
}