import React, { useEffect, useState } from "react";

export default function Links() {
    return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white" data-spy="affix" data-offset-top="510">
            <div className="container">
                <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse mt-sm-20 navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav brand">
                        <img src="assets/imgs/avatar.png" alt="" className="brand-img" />
                        <li className="brand-txt">
                            <h5 className="brand-title">Bikram Choudhury</h5>
                            <div className="brand-subtitle">Full stack Developer</div>
                        </li>
                    </ul>
                    <Navbar />
                </div>
            </div>
        </nav>
    )
}
function Navbar() {
    const [active, setActive] = useState('');
    useEffect(() => {
        const hash = window.location.hash;
        setActive(hash.replace('#', ''));
    }, []);

    const links = [
        { hash: '', text: 'Home' },
        { hash: 'about', text: 'About' },
        { hash: 'resume', text: 'Resume' },
        { hash: 'contact', text: 'Contact' },
    ];
    const handleClick = (hash) => {
        setActive(hash)
    }

    return (
        <ul className="navbar-nav ml-auto">
            {
                links.map((l, idx) => (
                    <li className="nav-item">
                        <a
                            href={`#${l.hash}`}
                            onClick={handleClick.bind(null, l.hash)}
                            className={`nav-link ${active === l.hash ? 'active' : ''}`}
                        >{l.text}</a>
                    </li>
                ))
            }
        </ul>
    )
}