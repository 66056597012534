import React from "react";
import SocialMedia from "./SocialMedia";

export default function Header() {
    const handleClick = () => {
        window.open('/assets/resource/Bikram-Choudhury-Resume.pdf', '_blank');
    }
    return (
        <header className="header">
            <div className="container">
                <SocialMedia />
                <div className="header-content">
                    <h4 className="header-subtitle" >Hello, I am</h4>
                    <h1 className="header-title">Bikram Choudhury</h1>
                    <h6 className="header-mono" >Full stack Developer</h6>
                    <button className="btn btn-primary btn-rounded" onClick={handleClick}><i className="ti-printer pr-2"></i>Print Resume</button>
                </div>
            </div>
        </header>
    )
}