import React from "react";
import PersonalInformation from "./PersonalInformation";
import MyExpertise from "./MyExpertise";
import WhoAmI from "./WhoAmI";

export default function AboutUs() {
    return (
        <div className="container-fluid">
        <div id="about" className="row about-section">
            <WhoAmI />
            <PersonalInformation />
            <MyExpertise />
        </div>
    </div>
    )
}