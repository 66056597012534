import React from "react";

export default function HireMe() {
    return (
        <section className="section bg-dark py-5">
            <div className="container text-center">
                <h2 className="text-light mb-5 font-weight-normal">I Am Available For FreeLance</h2>
                <a href="#contact" className="hire-me btn bg-primary w-lg" >Hire me</a>
            </div>
        </section>
    )
}