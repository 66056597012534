import React from "react";
import Each from "../../common/Each";

export default function MyExpertise() {
    const topSkills = [
        {
            title: 'Web Development',
            subTitle: 'Conceiving, designing, and coding a user interface flow that provides the smoothest user experience possible',
            icon: 'ti-widget'
        },
        {
            title: 'Mobile Development',
            subTitle: 'Build and maintain applications across various devices',
            icon: 'ti-stats-up'
        },
        {
            title: 'Technical Trainer',
            subTitle: 'Execute training sessions, webinars, workshops etc. according to organizational requirements.',
            icon: 'ti-paint-bucket'
        }
    ]
    return (
        <div className="col-lg-4 about-card">
            <h3 className="font-weight-light">My Expertise</h3>
            <span className="line mb-5"></span>
            <Each of={topSkills} render={Expertise} />
        </div>
    )
}

function Expertise({ title, subTitle, icon }) {
    // const {title, subTitle, icon} = props
    return (
        <div className="row">
            <div className="col-1 text-danger pt-1">
                <i className={`${icon} icon-lg`}></i>
            </div>
            <div className="col-10 ml-auto mr-3">
                <h6>{title}</h6>
                <p className="subtitle">{subTitle}</p>
                <hr />
            </div>
        </div>
    )
}