import React from "react";
import SocialMedia from '../SocialMedia';

export default function GetInTouch() {
    return (
        <div className="contact-info-card">
            <h4 className="contact-title">Get in touch</h4>
            <div className="row mb-2">
                <div className="col-1 pt-1 mr-1">
                    <i className="ti-mobile icon-md"></i>
                </div>
                <div className="col-10 ">
                    <h6 className="d-inline">Phone : <span className="text-muted">+91-7008529051</span></h6>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-1 pt-1 mr-1">
                    <i className="ti-map-alt icon-md"></i>
                </div>
                <div className="col-10">
                    <h6 className="d-inline">Address :<span className="text-muted">JP Nagar 6th phase, Bengaluru.</span></h6>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-1 pt-1 mr-1">
                    <i className="ti-envelope icon-md"></i>
                </div>
                <div className="col-10">
                    <h6 className="d-inline">Email :<span className="text-muted">bikram.choudhury527@gmail.com</span></h6>
                </div>
            </div>
            <SocialMedia dark />
        </div>
    )
}