import React, { useCallback, useMemo, useState } from "react";

function checkFormValidation({ subject, email, body }) {
    if (!subject || !body || !email) {
        return false;
    }
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(email)) {
        return false;
    }
    return true;
}
export default function SendMessage() {
    const [formData, setFormData] = useState({
        subject: '',
        email: '',
        body: ''
    });
    const isValid = useMemo(() => checkFormValidation(formData), [formData]);

    const handleClick = useCallback((event) => {
        event.preventDefault();
        const { email, subject, body } = formData;
        window.open("mailto:bikram.choudhury527@gmail.com?cc=" + email + "&subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body), "_blank");
    }, [formData]);

    const handleChange = useCallback((evnt) => {
        setFormData(data => ({
            ...data,
            [evnt.target.name]: evnt.target.value
        }));
    }, []);

    return (
        <div className="contact-form-card">
            <h4 className="contact-title">Send a message</h4>
            <form>
                <div className="form-group">
                    <input
                        className="form-control"
                        type="text"
                        name="subject"
                        placeholder="Subject *"
                        required
                        maxLength={150}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <input
                        className="form-control"
                        type="email"
                        name="email"
                        placeholder="CC: Email *"
                        required
                        maxLength={20}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <textarea
                        className="form-control"
                        id=""
                        placeholder="Message *"
                        rows="7"
                        name="body"
                        required
                        onChange={handleChange}
                        maxLength={500}
                    ></textarea>
                </div>
                <div className="form-group ">
                    <button
                        type="submit"
                        className="form-control btn btn-primary"
                        onClick={handleClick}
                        disabled={!isValid}
                    >Send Message</button>
                </div>
            </form>
        </div>
    );
}