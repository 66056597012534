import React from "react";

export default function SocialMedia(props) {
    const { dark } = props;
    const socialLinks = [
        {
            name: "Facebook",
            link: "https://www.facebook.com/biki.choudhury.376",
            icon: "ti-facebook"
        },
        {
            name: "Twitter",
            link: "https://twitter.com/bikram527",
            icon: "ti-twitter"
        },
        {
            name: "LinkedIn",
            link: "https://www.linkedin.com/in/bikram-choudhury",
            icon: "ti-linkedin"
        },
        {
            name: "Instagram",
            link: "https://www.instagram.com/bikram.choudhury527",
            icon: "ti-instagram"
        },
        {
            name: "github",
            link: "https://github.com/bikram-choudhury",
            icon: "ti-github"
        }
    ]
    return (
        <ul className="social-icons pt-3">
            {
                socialLinks.map(media => (
                    <li className="social-item" key={media.name}>
                        <a className={`social-link ${dark ? '': 'text-light'}`} rel="noreferrer" href={media.link} target="_blank">
                            <i className={media.icon} aria-hidden="true"></i>
                        </a>
                    </li>
                ))
            }
        </ul>
    )
}