import React from "react";

const Card = ({ heading, children }) => {
    return (
        <div className="card">
            <div className="card-header">
                <div className="mt-2">
                    <h4>{heading}</h4>
                    <span className="line"></span>
                </div>
            </div>
            <div className="card-body">
                {children}
            </div>
        </div>
    )
}

export default Card;