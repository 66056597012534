import React from "react";
import Each from "../../common/Each";
import Card from "../../common/Card";

export default function Languages() {
    const allLangs = [
        { name: 'English', score: 90 },
        { name: 'Hindi', score: 95 },
        { name: 'Odia', score: 100 }
    ];
    return (
        <Card heading="Languages">
            <Each of={allLangs} render={Language} />
        </Card>
    )
}

function Language(props) {
    const { name, score } = props;
    return (
        <>
            <h6>{name}</h6>
            <div className="progress mb-3">
                <div
                    className="progress-bar bg-danger"
                    role="progressbar"
                    style={{ width: `${score}%` }}
                    aria-valuenow={100 - score}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
        </>
    )
}