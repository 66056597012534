import React from 'react';
import Each from '../../common/Each';
import Card from '../../common/Card';

export default function Experience() {
    const allExperiences = [
        {
            joinedOn: 'Oct 2023',
            resignedOn: null,
            companyName: 'PayPal India Pvt Ltd',
            position: 'Senior Software Engineer',
            jobDesc: 'Develops software applications. Performs coding, debugging, testing and troubleshooting throughout the application development process. /n Being in a startup env, used to travel to client location for application demo & installation.'
        },
        {
            joinedOn: 'Feb 2021',
            resignedOn: 'Sep 2023',
            companyName: 'Altimetrik India Pvt Ltd',
            position: 'Senior Engineer',
            jobDesc: 'Develops software applications. Performs coding, debugging, testing and troubleshooting throughout the application development process. /n Being in a startup env, used to travel to client location for application demo & installation.'
        },
        {
            joinedOn: 'Nov 2018',
            resignedOn: 'Feb 2021',
            companyName: 'Infosys',
            position: 'Senior Associate Consultant',
            jobDesc: 'Develops software applications. Performs coding, debugging, testing and troubleshooting throughout the application development process. /n Being in a startup env, used to travel to client location for application demo & installation.'
        },
        {
            joinedOn: 'Apr 2017',
            resignedOn: 'Nov 2018',
            companyName: 'GLIDER.ai',
            position: 'Associate Software Developer',
            jobDesc: 'Develops software applications. Performs coding, debugging, testing and troubleshooting throughout the application development process. /n Being in a startup env, used to travel to client location for application demo & installation.'
        },
        {
            joinedOn: 'Sept 2015',
            resignedOn: 'Mar 2017',
            companyName: 'Bridge I Technologies',
            position: 'Software Developer',
            jobDesc: 'Develops software applications. Performs coding, debugging, testing and troubleshooting throughout the application development process. /n Being in a startup env, used to travel to client location for application demo & installation.'
        },
    ];
    return (
        <Card heading="Expertise">
            <Each
                of={allExperiences}
                render={(item, index) => {
                    return (
                        <>
                            <Exposure {...item} />
                            {(index + 1) < allExperiences.length ? <hr /> : null}
                        </>
                    )
                }}
            />
        </Card>
    );
}

function Exposure(props) {
    const { joinedOn, resignedOn, companyName, position, jobDesc } = props;
    return (
        <>
            <h6 className="title text-danger">{joinedOn} - {resignedOn}</h6>
            <span><strong>{companyName}</strong></span>
            <p><i>{position}</i></p>
            {jobDesc.split('/n').map(text => <p className="subtitle">{text}</p>)}
        </>
    )
}